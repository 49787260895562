import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step crypto nacl box open`}</strong>{` -- authenticate and decrypt a box produced by seal`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step crypto nacl box open <nonce> <sender-pub-key> <priv-key>
[--raw]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p>{`Authenticate and decrypt a box produced by seal using the specified KEY. If
PRIV_KEY is encrypted you will be prompted for the password. The sealed box is
read from STDIN and the decrypted plaintext is written to STDOUT.`}</p>
    <p>{`This command uses an implementation of NaCl's crypto_box_open function.`}</p>
    <p>{`For examples, see `}<strong parentName="p">{`step help crypto nacl box`}</strong>{`.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`nonce`}</inlineCode>{`
The nonce provided when the box was sealed.`}</p>
    <p>{`To use a binary nonce use the prefix 'base64:' and the standard base64
encoding. e.g. base64:081D3pFPBkwx1bURR9HQjiYbAUxigo0Z`}</p>
    <p><inlineCode parentName="p">{`sender-pub-key`}</inlineCode>{`
The path to the public key of the peer that produced the sealed box.`}</p>
    <p><inlineCode parentName="p">{`priv-key`}</inlineCode>{`
The path to the private key used to open the box.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--raw`}</strong>{`
Indicates that input is not base64 encoded`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      